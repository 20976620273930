import './image-gallery.scss';

// import { Slider } from './../../components/slider/slider';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';

class ImageGallery {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-imagegallery'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$imageGallery = $element;
        this.$imageGallerySlider = this.$imageGallery.querySelector('[' + this.settings.initAttr + '="slider"]');
        this.$originalSliderClone = this.$imageGallerySlider.cloneNode(true);
        this.$$imageGallerySlides = this.$imageGallery.querySelectorAll('[' + this.settings.initAttr + '="slide"]');
        this.$$enlargeButtons = this.$imageGallery.querySelectorAll('[' + this.settings.initAttr + '="enlarge"]');
        this.$overlay = null;
        this.$closeOverlay = null;

        this.moduleType = this.$imageGallery.getAttribute('data-imagegallery-type');
        this.imageGallerySlider = null;
        this.overlaySlider = null;
        this.open = false;
        this.buttonIndex = 0;

        this.resizeHandler = window.resizeHandler;
        this.sliderActive = false;
        this.overlaySliderActive = false;

        if (this.$$imageGallerySlides.length > 1) {
            import(
                /* webpackChunkName: "slider/slider.js" */
                './../../components/slider/slider').then(({ Slider }) => {
                this.Slider = Slider;
                this.initialize();
            });
        }
        // this.initialize();
    }

    initialize () {
        this.checkSize();
        this.resizeHandler.customFunctions.push(() => {
            this.checkSize();
        });

        this.setEvents();
    }

    checkSize () {
        if (this.moduleType === 'slider') {
            if (window.innerWidth < 768 && this.$$imageGallerySlides.length > 1) {
                if (this.sliderActive === false) {
                    this.initSlider(this.imageGallerySlider);
                }
            } else if (window.innerWidth >= 768 && this.$$imageGallerySlides.length > 2) {
                if (this.sliderActive === false) {
                    this.initSlider(this.imageGallerySlider);
                }
            } else {
                if (this.sliderActive === true) {
                    this.destroySlider(this.imageGallerySlider);
                    this.sliderActive = false;
                }
            }
        } else if (this.moduleType === 'tiles') {
            if (window.innerWidth < 768 && this.$$imageGallerySlides.length > 3) {
                if (this.sliderActive === false) {
                    this.initSlider(this.imageGallerySlider);
                }
            } else {
                if (this.sliderActive === true) {
                    this.destroySlider(this.imageGallerySlider);
                    this.sliderActive = false;
                }
            }
        }
    }

    closeOverlay () {
        enableBodyScroll(this.$overlay);
        this.$overlay.classList.remove('is--visible');
        this.destroySlider(this.overlaySlider);
        this.overlaySlider = null;
        this.overlaySliderActive = false;
        this.open = false;

        setTimeout(() => {
            this.$overlay.remove();
            this.$overlay = null;
            this.$closeOverlay = null;
        }, 500);
    }

    enlargeImage () {
        if (!this.open) {
            this.$overlay = document.createElement('section');
            this.$overlay.setAttribute('data-imagegallery', 'overlay');
            this.$overlay.classList.add('image-gallery__overlay');
            const template = '<div class="image-gallery__overlay-inner"><div data-imagegallery="close" class="image-gallery__overlay-close icon-close"></div></div>';
            this.$overlay.innerHTML = template;
            document.body.appendChild(this.$overlay);
            disableBodyScroll(this.$overlay);
            this.open = true;

            const $container = this.$overlay.querySelector('.image-gallery__overlay-inner');

            // Remove enlarge buttons in overlay
            const $$overlayEnlargeButtons = this.$originalSliderClone.querySelectorAll('.image-gallery__item-icon');

            if ($$overlayEnlargeButtons.length > 0) {
                for (let index = 0; index < $$overlayEnlargeButtons.length; index++) {
                    $$overlayEnlargeButtons[index].remove();
                }
            }

            $container.appendChild(this.$originalSliderClone);

            this.$closeOverlay = document.querySelector('[' + this.settings.initAttr + '="close"]');
            this.$closeOverlay.addEventListener('click', (e) => {
                this.closeOverlay();
            });

            if (this.$$imageGallerySlides.length > 1) {
                this.initSlider(this.$originalSliderClone);
            }

            setTimeout(() => {
                this.$overlay.classList.add('is--visible');
            }, 500);
        }
    }

    initSlider (slider) {
        if (slider === this.imageGallerySlider) {
            this.imageGallerySlider = new this.Slider(this.$imageGallerySlider, {
                modules: [A11y, Navigation],
                speed: 500,
                slidesPerView: 'auto',
                slidesPerGroup: 1,
                centeredSlides: true,
                centeredSlidesBounds: true,
                initAttr: this.settings.initAttr,
                centerInsufficientSlides: true
            });

            this.sliderActive = true;
        } else if (slider === this.$originalSliderClone) {
            this.overlaySlider = new this.Slider(this.$originalSliderClone, {
                modules: [A11y, Navigation, Pagination, Scrollbar],
                speed: 500,
                slidesPerView: 'auto',
                slidesPerGroup: 1,
                fractionText: '•',
                centeredSlides: true,
                centeredSlidesBounds: true,
                initAttr: this.settings.initAttr,
                centerInsufficientSlides: true,
                scrollbar: true,
                scrollbarAppendToControls: true,
                counter: true,
                counterCustom: true,
                initialSlide: this.buttonIndex
            });

            this.overlaySliderActive = true;
        }
    }

    destroySlider (slider) {
        if (typeof slider !== 'undefined' && slider !== null) {
            slider.destroy();
        }
    }

    setEvents () {
        if (this.$$enlargeButtons.length > 0) {
            this.$$enlargeButtons.forEach(button => {
                button.addEventListener('click', (e) => {
                    this.buttonIndex = Array.from(this.$$enlargeButtons).indexOf(e.target);
                    this.enlargeImage();
                    e.preventDefault();
                });
            });
        }
    }
}

export { ImageGallery };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$imageGallery = $context.querySelectorAll('[data-imagegallery="root"]');
        for (let i = 0; i < $$imageGallery.length; i++) {
            const $imageGallery = $$imageGallery[i];

            const imageGalleryAPI = new ImageGallery($imageGallery);
            $imageGallery.API = imageGalleryAPI;
        }
    }
});
